import { Controller } from "@hotwired/stimulus"
import I18n from "helpers/i18n"
import { parseHTMLFragment } from "helpers/dom"

export default class extends Controller {
  static targets = [
    "annualUsersInput",
    "monthlyPricePerYear",
    "advert",
    "module",
    "price",
    "annualPrice",
    "savings",
    "annualSelected",
    "receipt",
    "inputBox",
    "userCountSummary",
    "wfTerms",
    "payrollTerms",
    "hrTerms",
  ]
  static values = {
    currentPrice: Number,
    currentUsers: Number,
    allowedToChangeAnnualUsers: Boolean,
    payrollOnly: Boolean,
    subscriptions: Array,
  }

  initialize() {
    this.hideClass = "hidden"
    this.showClass = "block"

    this.ensureWorkforceManagementSelected()
    this.toggleBillingType()
    this.renderTerms()
  }

  showAnnualUsers() {
    this.inputBoxTarget.classList.remove(this.hideClass)
    this.inputBoxTarget.classList.add(this.showClass)
    this.annualUsersInputTarget.disabled = false
  }

  hideAnnualUsers() {
    this.inputBoxTarget.classList.add(this.hideClass)
    this.inputBoxTarget.classList.remove(this.showClass)
    this.inputBoxTarget.disabled = true
  }

  hideAdvert() {
    this.monthlyPricePerYearTarget.classList.add(this.hideClass)
    this.advertTarget.classList.add(this.hideClass)
  }

  showAdvert() {
    this.monthlyPricePerYearTarget.classList.remove(this.hideClass)
    this.advertTarget.classList.remove(this.hideClass)
  }

  toggleBillingType() {
    this.generateQuotes()
    if (this.allowedToChangeAnnualUsersValue) {
      if (this.annualSelectedTarget.checked) {
        this.showAnnualUsers()
        this.hideAdvert()
      } else {
        this.hideAnnualUsers()
        this.showAdvert()
      }
    } else {
      this.annualUsersInputTarget.disabled = true
      this.hideAdvert()
    }
  }

  applyAnnualDiscount(amount) {
    return (amount * 0.8).toFixed(2)
  }

  savings(price) {
    const savingAmount = price - this.applyAnnualDiscount(price)
    return savingAmount.toFixed(2)
  }

  changePrice(event) {
    if (event.target.value === "all_in_one") {
      this.moduleTargets.forEach((module) => {
        if (module.value !== "all_in_one") {
          module.checked = false
        }
      })
    }

    if (this.allCustomPlansSelected()) {
      this.moduleTarget.click()
    }

    this.generateQuotes()
    this.toggleBillingType()
    this.renderTerms()
  }

  calculatePrice() {
    let price = 0

    this.moduleTargets.forEach((module) => {
      if (module.checked) {
        price += parseFloat(this.moduleUnitPrice(module.value))
      }
    })
    return price.toFixed(2)
  }

  createReceipt() {
    this.userCountSummary()
    this.receiptTarget.innerHTML = ""
    this.moduleTargets.forEach((module) => {
      if (module.checked) {
        this.receiptTarget.appendChild(parseHTMLFragment(this.createReceiptItem(module)))
      }
    })
    if (this.annualSelectedTarget.checked) {
      this.receiptTarget.appendChild(
        parseHTMLFragment(`
      <div>
        <span class="text-blue-900 font-semibold">Subtotal</span>
        <p class="text-blue-900 text-lg">$${this.numberWithCommas(this.annualPriceNoDiscount())}</p>
      </div>
      <div class= "rounded-lg bg-blue-50 py-3 px-1">
        <div class="text-blue-800 flex space-x-1">
          <h6 class="text-blue-800">Discount</h6>
          <p class= "self-end text-blue-800 text-sm p-0 italic">20% off with annual plan</p>
        </div>
        <p class= "text-blue-800 text-lg"> $${this.numberWithCommas(this.savings(this.annualPriceNoDiscount()))}</p>
      </div>
      `)
      )
    }
  }

  userCountSummary() {
    this.userCountSummaryTarget.querySelector("span").innerText = this.annualSelectedTarget.checked
      ? "Users on Plan"
      : "Current End Users"
    this.userCountSummaryTarget.querySelector("p").innerText = this.userCount()
  }

  moduleUnitPrice(module) {
    return this.subscriptionsValue.find((sub) => sub.service === module).unit_price
  }

  autoSelectEnabledModules(event) {
    const subscriptionsValue = this.subscriptionsValue

    this.moduleTargets.forEach((module) => {
      if (module.value !== "all_in_one") {
        module.checked = subscriptionsValue.find((sub) => sub.service === module.value).enabled
      }
    })
    this.changePrice(event)
  }

  pricePerUser() {
    if (this.annualSelectedTarget.checked) {
      return this.applyAnnualDiscount(this.calculatePrice())
    } else {
      return this.calculatePrice()
    }
  }

  overallPrice() {
    return (this.pricePerUser() * this.userCount() * 12).toFixed(2)
  }

  monthlyPrice() {
    return (this.pricePerUser() * this.userCount()).toFixed(2)
  }

  annualPriceNoDiscount() {
    return (this.calculatePrice() * this.userCount() * 12).toFixed(2)
  }

  userCount() {
    if (this.annualSelectedTarget.checked) {
      return this.annualUsersInputTarget.value
    } else {
      return this.currentUsersValue
    }
  }

  generateQuotes() {
    if (this.annualSelectedTarget.checked) {
      this.priceTarget.innerText = `$${this.numberWithCommas(this.overallPrice())} per annum`
    } else {
      this.priceTarget.innerText = `$${this.numberWithCommas(this.monthlyPrice())} per month`
      this.monthlyPricePerYearTarget.innerText = `$${this.numberWithCommas(this.overallPrice())} per annum`
      this.annualPriceTarget.innerText = `$${this.numberWithCommas(
        this.applyAnnualDiscount(this.annualPriceNoDiscount())
      )} per annum`
      this.savingsTarget.innerText = this.savings(this.annualPriceNoDiscount())
    }
    this.createReceipt()
  }

  allCustomPlansSelected() {
    return this.moduleTargets
      .filter((checkbox) => checkbox.value !== "all_in_one")
      .every((checkbox) => checkbox.checked)
  }

  createReceiptItem(module) {
    if (this.annualSelectedTarget.checked) {
      return `
      <div class="text-blue-900">
          <h6 class="text-blue-900">${I18n.t(`js.payments.${module.value}`)}</h6>
          <p class= "text-blue-900">$${this.moduleUnitPrice(
            module.value
          )} monthly cost per user x ${this.userCount()} users x 12 months = $${this.numberWithCommas(
        this.moduleUnitPrice(module.value) * this.userCount() * 12
      )}/year</p>
        </div>
      `
    } else {
      return `
      <div class="text-blue-900">
          <h6 class="text-blue-900">${I18n.t(`js.payments.${module.value}`)}</h6>
          <p class= "text-blue-900">$${this.moduleUnitPrice(
            module.value
          )} monthly cost per user x ${this.userCount()} users = $${this.numberWithCommas(
        this.moduleUnitPrice(module.value) * this.userCount()
      )} monthly</p>
        </div>
        `
    }
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  renderTerms() {
    if (this.moduleTargets[0].checked) {
      this.showAllTerms()
    } else {
      for (let i = 1; i < this.moduleTargets.length; i++) {
        if (this.moduleTargets[i].checked) {
          this.showTerms(this.getTermsTarget(this.moduleTargets[i].value))
        } else {
          this.hideTerms(this.getTermsTarget(this.moduleTargets[i].value))
        }
      }
    }
  }

  getTermsTarget(module) {
    switch (module) {
      case "workforce_management":
        return this.wfTermsTarget
      case "payroll":
        return this.payrollTermsTarget
      case "hr":
        return this.hrTermsTarget
    }
  }

  showTerms(termsTarget) {
    termsTarget.classList.remove("hidden")
  }

  hideTerms(termsTarget) {
    termsTarget.classList.add("hidden")
  }

  showAllTerms() {
    this.wfTermsTarget.classList.remove("hidden")
    this.payrollTermsTarget.classList.remove("hidden")
    this.hrTermsTarget.classList.remove("hidden")
  }

  ensureWorkforceManagementSelected() {
    if (!this.moduleTargets[0].checked && !this.payrollOnlyValue) {
      this.moduleTargets[1].checked = true
    }
  }
}
